@import "vars";

/* Styling for ToC */
.section-nav {
	margin: 5px 5px 5px 5px;
	padding: 10px 30px;
	border: 3px solid #E8E8E8;
	border-radius: 3px;
}

.content {
    a:hover {
        text-decoration: underline;
    }
    ol {
        counter-reset: list;
    }
    ol > li {
        list-style-position: none;
        position: relative;
        list-style: none;
        margin-top: 5px;
        margin-bottom: 5px;
	margin-left: 8px;
	margin-right: 8px;
    }
    ul > li {
        margin: 5px 0 5px 0;
    }
    ul {
        padding: 0 20px 0 20px;
        margin-top: 5px;
        margin-bottom: 5px;
	margin-left: 8px;
	margin-right: 8px;
    }
    ol {
        border-left: 5px solid #949494;
        padding: 0 30px 0 30px;
	margin-left: 25px;
	margin-right: 25px;
    }
    ol > li:before {
        content: "[" counter(list, decimal) "] ";
        counter-increment: list;
        position: absolute;
        left: -30px;
    }
    blockquote {
        //border: 1px solid black;
        border-left: 5px solid #949494;
        margin: 20px 12px 20px 12px;
        padding: 2px 8px 2px 8px;
        font-style: italic;
	font-family: "Inconsolata";
    }
    .profile-picture {
        height: $image-size;
        width: $image-size;
	border: 0px;
        position: relative;
        //margin: -10px 0 0 15px;
        float: right;
        //border-radius: 50%;
        p & {
            padding: 0;
            margin: 0;
        }
        @media (max-width: $small-width) {
            img {
                margin-top: -5px;
            }
        }
    }
    img {
        text-align: center;
        width: 100%;
	padding: 2px;
	border: 2px solid blue;
    }

    h2 {
	    color: $h2-colour;
    }
    h3 {
	    color: $h3-colour;
    }
}
