@import "vars";

/*
 * Basic
 */

* {
	color: lightgrey;
}

html {
    height: 100%;
    margin: 5;
    padding: 2;
}
body {
    margin: 0;
    font-family: "Arial";
    font-size: 18px;
    background-color: rgba(0, 7, 200, 0.78);
    height: 100%;
}
a {
    text-decoration: none;
    color: $accent;
}
a:hover {
    text-decoration: underline;
}
hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/*
 * Navbar
 */

#author-name {
    font-size: 30px;
    color: white;
    display: inline-block;
}
.navbar {
    padding: 50px 0 50px 0;
}
.navbar-ul {
    display: inline-block;
    margin: 0;
    padding: 5px 0 5px 0;
    margin-left: 20px;
}
.nav-list {
    list-style-type: none;
    margin: 0 5px 0 10px;
}
.alignable {
    display: inline-block;
}
.pull-left {
    @extend .alignable;
    float: left;
}
.pull-right {
    @extend .alignable;
    float: right;
}

/* Divs */

.container {
    max-width: $max-width;
    margin: auto;
}
.wrapper {
    min-height: 100%;
}

/* Mobile */

@media (max-width: 800px) {
    .container {
        max-width: $med-width;
        transition: 0.3s ease all;
    }
}

@media (max-width: 650px) {
    .container {
        max-width: 83%;
    }
    .navbar-ul {
        margin-top: 5px;
    }
}

@media (max-width: 580px) {
    #author-name {
        display: inline-block;
    }
}

@import "typography";
@import "tables";
