/*
 * Customization Variables
 */

$accent: rgba(255, 118, 0, 0.91);
$image-size: 180px;
$h2-colour: lightgreen;
$h3-colour: lightblue;

/*
 * Build Variables, don't touch
 */

$max-width: 700px;
$med-width: 600px;
$small-width: 480px;
